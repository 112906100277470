import { Button as ButtonBase } from "@musicaudienceexchange/melodies/Button";
import { SvgImageLarge } from "@musicaudienceexchange/melodies/Svgs/ImageLarge";
import { Selected } from "@musicaudienceexchange/melodies/Text";
import { Div } from "@musicaudienceexchange/melodies/utils";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import SelectImageModal from "./SelectImageModal";

const ImageButtons = styled(Div)`
  position: absolute;
`;

const Options = styled(Div)`
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.2s;
`;

type ImgContainerProps = {
  src?: string;
};
const ImgContainer = styled(Div)<ImgContainerProps>`
  box-sizing: border-box;
  border: 1px solid
    ${(p: any) => (p.src ? p.theme.colors.gray1 : p.theme.colors.gray4)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  ${(props) => props.theme.mediaQueries.mobile} {
    border-style: solid;
    ${(p: any) =>
      p.src
        ? css`
            border-width: 1px;
          `
        : css`
            border-width: 2px;
          `};
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    :hover {
      cursor: pointer;
      ${Options} {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
`;

const Label = styled(Div)`
  color: ${(p) => p.theme.colors.black80};
  ${(props) => props.theme.mediaQueries.mobile} {
    margin-top: 4px;
    font-size: 12px;
    line-height: 15px;
  }
`;

const MediaIcon = styled(SvgImageLarge)`
  width: 36px;
  height: 36px;
  path {
    fill: ${(props) => props.theme.colors.black40};
  }
  ${(props) => props.theme.mediaQueries.mobile} {
    width: 22px;
    height: 22px;
  }
`;

const ButtonWrapper = styled(Div)`
  width: 70%;
  ${(p) => p.theme.css.centered};
`;

const Button = styled(ButtonBase)`
  color: ${(p) => p.theme.colors.gray2};
`;

const ImageInput = ({ value, onSelect, name, ...props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    if (value)
      setImgUrl(typeof value === "string" ? value : URL.createObjectURL(value));
  }, [value]);

  return (
    <>
      <SelectImageModal
        isOpen={isModalOpen}
        onSelect={onSelect}
        onClose={() => setIsModalOpen(false)}
        {...props}
      />
      <ImgContainer centered src={imgUrl} onClick={() => setIsModalOpen(true)}>
        {imgUrl ? (
          <>
            <Options>
              <ButtonWrapper>
                <Button variant="tertiary">Replace</Button>
              </ButtonWrapper>
            </Options>
            <Img src={imgUrl} />
          </>
        ) : (
          <ImageButtons flexColumn alignCenter>
            <MediaIcon />
            <Selected mt={18}>Add Image</Selected>
          </ImageButtons>
        )}
      </ImgContainer>
    </>
  );
};

export default ImageInput;
