import React, { useEffect, useState } from "react";
import type { DocumentData } from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
} from "firebase/storage";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { addToast } from "@musicaudienceexchange/melodies/Toast";
import { useAppContext } from "AppContextProvider";
import { SelectImageFields } from "components/common/SelectImageFields";

import { getBlobFromUrl } from "utils";
import { Button } from "@musicaudienceexchange/melodies/Button";
import {
  Form,
  FormikSumbitButton,
  FormikTextInput,
  yupRequired,
} from "@musicaudienceexchange/melodies/forms";

import { useUser } from "auth";

const getBlobUrl = (userId: string, blob: Blob) =>
  new Promise<string>((resolve, reject) => {
    const storage = getStorage();
    const mimeType = blob.type;
    const ext = mimeType.split(";")[0].split("/")[1];

    const storageRef = ref(
      storage,
      `/user_profile/${userId}/${Math.random()
        .toString(36)
        .substring(2)}.${ext}`
    );

    const task = uploadBytesResumable(storageRef, blob);

    task.on(
      "state_changed",
      (snap) =>
        console.log(
          "progress",
          (snap.bytesTransferred / snap.totalBytes) * 100
        ),
      (error) => {
        console.error("uploadError", error);
        reject(error);
      },
      () => getDownloadURL(task.snapshot.ref).then(resolve)
    );
  });

const imageFields = [
  {
    name: "mobileImg",
    label: "Profile Image",
    aspectRatio: 1,
    description: "test",
    dropzoneDescription: "test2",
    width: "400px",
  },
];

interface ContactInfoFormProps {
  profile: DocumentData;
  setIsEditing: (isEditing: boolean) => unknown;
}

export const ContactInfoForm: React.FC<ContactInfoFormProps> = ({
  profile,
  setIsEditing,
}) => {
  const { setAppIsLoading } = useAppContext();
  const { updateName, updatePhotoURL, user } = useUser();
  const [profileImg, setProfileImg] = useState({
    blob: null,
    isInitialValue: false,
  });

  const {
    name: { firstName, lastName },
  } = profile;
  const fields = [
    {
      label: "First Name",
      name: "firstName",
      validation: yupRequired,
      initialValue: firstName,
    },
    {
      label: "Last Name",
      name: "lastName",
      validation: yupRequired,
      initialValue: lastName,
    },
  ];

  useEffect(() => {
    (async () => {
      if (user.photoURL) {
        const blob = await getBlobFromUrl(profile.photoURL || user.photoURL);
        setProfileImg({ blob, isInitialValue: true });
      }
    })();
  }, []);

  const onSubmitForm = async (values) => {
    setAppIsLoading(true);
    await updateName(values.firstName, values.lastName);
    if (profileImg.blob) {
      const url = await getBlobUrl(user.uid, profileImg.blob);
      await updatePhotoURL(url);
    }
    addToast("Contact information updated.");
    setAppIsLoading(false);
    setIsEditing(false);
  };

  const fieldsUI = (
    <>
      <FormikTextInput
        label="First Name"
        placeholder="Add first name"
        name="firstName"
      />
      <FormikTextInput
        mt={20}
        label="Last Name"
        placeholder="Add last name"
        name="lastName"
      />
    </>
  );

  return (
    <Div>
      <SelectImageFields
        values={{ mobileImg: profileImg }}
        setValues={(v) => setProfileImg(v.mobileImg)}
        fields={imageFields}
      />
      <Form fields={fields} onSubmit={onSubmitForm} mt={30}>
        {fieldsUI}
        <Div dflex mt={40}>
          <Button
            mr={10}
            variant="tertiary"
            onClick={() => setIsEditing(false)}
          >
            Cancel
          </Button>
          <FormikSumbitButton ml={10}>Update</FormikSumbitButton>
        </Div>
      </Form>
    </Div>
  );
};
