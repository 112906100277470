import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useIsMobile } from "../../../../../hooks";
import {
  Modal as ModalBase,
  ModalContainer,
  ModalWrapper,
} from "@musicaudienceexchange/melodies/Modal";
import ImageCrop from "./ImageCrop";
import ImageDropzone from "./ImageDropzone";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { colors } from "@musicaudienceexchange/melodies/Theme/colors";
import { Button } from "@musicaudienceexchange/melodies/Button";
import { Body1 } from "@musicaudienceexchange/melodies/Text";

const imageStylesDesktop = {
  height: "300px",
};

const imageStylesMobile = {
  height: "250px",
};

const ImageWrapper = styled(Div)`
  width: 100%;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.gray3};
  display: flex;
  align-items: center;
  justify-content: center;
  ${imageStylesDesktop};
  ${(props) => props.theme.mediaQueries.mobile} {
    ${imageStylesMobile}
  }
`;

const Modal = styled(ModalBase)`
  ${ModalContainer} {
    margin-top: 7vh;
  }
  ${ModalWrapper} {
    ${(p) => p.theme.mediaQueries.desktop} {
      width: 600px;
    }
  }
`;

const DescriptionText = styled(Div)`
  ${(p) => p.theme.mediaQueries.mobile} {
    font-size: 17px;
  }
`;

type MobileButtonProps = {
  disabled?: boolean;
};
const MobileButton = styled(Div)<MobileButtonProps>`
  color: ${(p) => p.theme.colors.blue80};
  font-weight: bold;
  user-select: none;
  ${(p) => p.theme.css.centered};
  ${(p: any) =>
    p.disabled
      ? css`
          pointer-events: none;
          opacity: 0.5;
        `
      : css`
          :hover {
            cursor: pointer;
            opacity: 0.8;
          }
        `};
  ${(p) => p.theme.mediaQueries.desktop} {
    display: none;
  }
`;

const SelectImageModal = ({ isOpen, onClose, onSelect = null, ...props }) => {
  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [isOpen]);

  return (
    <Modal
      header={`Add ${props.label}`}
      isOpen={isOpen}
      withCloseIcon={false}
      onClose={onClose}
      withBackdropClose={false}
    >
      <ModalContent
        {...{
          onClose,
          onSelect,
          ...props,
        }}
      />
    </Modal>
  );
};

const ButtonsContainer = styled(Div)`
  width: 300px;
  ${(p) => p.theme.mediaQueries.mobile} {
    width: 180px;
  }
`;

const ModalContent = ({ onClose, onSelect, ...props }) => {
  const [image, setImage] = useState(null);
  const [croppedImg, setCroppedImg] = useState(null);
  const { aspectRatio, description, dropzoneDescription } = props;

  const headerUI = (
    <Div c={colors.black80}>
      <DescriptionText fs={17} mt={5} lite>
        {description}
      </DescriptionText>
    </Div>
  );

  const saveButtonUI = (
    <Div
      w100
      onClick={() => {
        if (croppedImg) {
          onSelect(croppedImg);
          onClose();
        }
      }}
    >
      <Button forDesktop isDisabled={!croppedImg}>
        Save
      </Button>
      <MobileButton disabled={!croppedImg}>Save</MobileButton>
    </Div>
  );

  const cancelButtonUI = (
    <Div onClick={onClose} w100>
      <Button forDesktop variant="secondaryPrimary">
        Cancel
      </Button>
      <MobileButton>Cancel</MobileButton>
    </Div>
  );

  const isMobile = useIsMobile();
  const imageStyles = isMobile ? imageStylesMobile : imageStylesDesktop;
  return (
    <>
      {headerUI}
      <Div mb={5}>
        <Body1 mb={5} mt={20}>
          {image ? "Preview" : "Image Upload"}
        </Body1>
        {!image ? (
          <ImageDropzone
            onSelect={(img) => setImage(img)}
            Wrapper={ImageWrapper}
            placeholderText={dropzoneDescription}
          />
        ) : (
          <ImageCrop
            srcImg={image}
            options={{ aspect: aspectRatio }}
            onCrop={(img) => setCroppedImg(img)}
            Wrapper={ImageWrapper}
            style={imageStyles}
          />
        )}
        <Div justifyEnd w100>
          <ButtonsContainer flexRowReverse mt={30}>
            {saveButtonUI}
            <Div ml={8} mr={8} />
            {cancelButtonUI}
          </ButtonsContainer>
        </Div>
      </Div>
    </>
  );
};

export default SelectImageModal;
