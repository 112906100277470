import React from "react";
import { Redirect } from "react-router-dom";
import { AuthUrlParams, useAuthQueryParams } from "components/common";

import { useUser } from "auth";

export const AuthRedirect: React.FC<{ to: string }> = ({ to }) => {
  const { isLoggedIn } = useUser();
  const { signOut, redirect, focusPassword } = useAuthQueryParams([
    "signOut",
    "redirect",
    "focusPassword",
  ]);

  const params: AuthUrlParams = {};
  if (isLoggedIn && signOut) {
    params.signOut = signOut;
  }
  if (focusPassword) {
    params.focusPassword = focusPassword;
  }

  if (redirect) {
    params.redirect = redirect;
  }

  const qs = new URLSearchParams(params);

  return <Redirect to={{ pathname: to, search: `?${qs.toString()}` }} />;
};
