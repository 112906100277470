import { ThemeContext } from "styled-components";
import { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router";

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}

export const useIsMobile = () => {
  const {
    mediaQueries: { mobile },
  } = useContext(ThemeContext);
  return useMediaQuery(mobile);
};

export const useInput = (value, onChange, onChangeTimeout = 400) => {
  const [text, setText] = useState(value);
  const tid = useRef(null);

  // improves performance in forms
  useEffect(() => {
    if (text !== value) {
      clearTimeout(tid.current);
      tid.current = setTimeout(() => onChange(text), onChangeTimeout);
    }
  }, [text]);

  useEffect(() => {
    setText(value);
  }, [value]);

  return { text, setText };
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useDivTop = () => {
  const ref = useRef(null);
  const [divTop, setDivTop] = useState(null);
  useEffect(() => {
    const setTop = () => setDivTop(ref?.current?.getBoundingClientRect().top);
    setTop();
    window.addEventListener("resize", setTop);
    return () => {
      window.removeEventListener("resize", setTop);
    };
  }, []);
  return { ref, divTop };
};

export const useWindowInnerHeight = () => {
  const [windowInnerHeight, setInnerHeight] = useState(window.innerHeight);
  useEffect(() => {
    const _setInnerHeight = () => setInnerHeight(window.innerHeight);
    _setInnerHeight();
    window.addEventListener("resize", _setInnerHeight);
    return () => {
      window.removeEventListener("resize", _setInnerHeight);
    };
  }, []);
  return windowInnerHeight;
};

interface WindowSize {
  width: number;
  height: number;
}

export const useWindowInnerSize = () => {
  const [innerSize, setInnerSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const _setInnerSize = () => {
      setInnerSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    _setInnerSize();
    window.addEventListener("resize", _setInnerSize);
    return () => {
      window.removeEventListener("resize", _setInnerSize);
    };
  }, []);
  return innerSize;
};

export const useDebounce = (value, onChange, timeoutMs = 1000) => {
  const [_value, set_Value] = useState(value);
  const tid = useRef(null);

  useEffect(() => {
    if (_value !== value) {
      clearTimeout(tid.current);
      tid.current = setTimeout(() => onChange(_value), timeoutMs);
    }
  }, [_value]);

  useEffect(() => {
    set_Value(value);
  }, [value]);

  return { _value, set_Value };
};

export const useQueryParams = (
  queryParams: string[]
): { [queryParam: string]: string } => {
  const res = {};
  const params = new URLSearchParams(useLocation().search);
  queryParams.forEach((p) => (res[p] = params.get(p)));
  return res;
};
