import { AppLoading } from "components/AppLoading";
import { createContext, useContext, useState } from "react";

type State = {
  appIsLoading: boolean;
  setAppIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  newEventId: any;
  setNewEventId: React.Dispatch<any>;
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const AppContext = createContext<State>({} as State);

export const AppContextProvider = ({ children }) => {
  const [appIsLoading, setAppIsLoading] = useState(false);
  const [newEventId, setNewEventId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const value = {
    newEventId,
    setNewEventId,
    appIsLoading,
    setAppIsLoading,
    isDrawerOpen,
    setIsDrawerOpen,
  };
  return (
    <AppContext.Provider value={value}>
      {appIsLoading && <AppLoading />}
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
