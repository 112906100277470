import React, { useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, DocumentData } from "firebase/firestore";

import { sessionApi } from "@musicaudienceexchange/firebase-auth-ui";
import { Divider, useAuthQueryParams, useHistoryPush } from "components/common";
import styled from "styled-components";
import { ROUTES } from "..";
import { ContactInfo } from "./ContactInfo";
import myAccountSvg from "./my-account.svg";
import { Navigation } from "../common/Navigation";
import { PasswordForm } from "./PasswordForm";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { AppLoading } from "components/AppLoading";
import { AppContainer, AppLayout } from "components/common/layout";
import { colors } from "@musicaudienceexchange/melodies/Theme/colors";
import { Footer } from "components/common/Footer";
import { H2 } from "@musicaudienceexchange/melodies/Text";

import { useUser } from "auth";
import { useFirebase } from "providers/FirebaseProvider";

const IllustrationImg = styled.img`
  width: 380px;
  height: 352px;
  ${(p) => p.theme.mediaQueries.mobile} {
    width: 186px;
    height: 172px;
  }
`;

const IllustrationImgWrapper = styled(Div)`
  margin-bottom: 44px;
  margin-top: -133px;
  ${(p) => p.theme.css.justifyEnd};
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-bottom: 50px;
    margin-top: 110px;
    ${(p) => p.theme.css.centered};
  }
`;

const illustrationUI = (
  <IllustrationImgWrapper>
    <IllustrationImg src={myAccountSvg} />
  </IllustrationImgWrapper>
);

export const Section = styled(Div)`
  padding: 20px 0px;
  ${(props) => props.theme.mediaQueries.mobile} {
    padding-right: 0;
    padding-left: 0;
  }
  color: ${(props) => props.theme.colors.black80};
  ${(props) => props.theme.mediaQueries.mobile} {
    box-shadow: none;
    padding-right: 0;
    padding-left: 0;
  }
`;

const SectionWrapper = styled(Div)`
  margin-top: 20px;
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-top: 10px;
  }
`;

const useAuthRedirect = (isNewUser: boolean) => {
  const { clearSession } = useUser();
  const { redirect: redirectUrl, signOut } = useAuthQueryParams([
    "redirect",
    "signOut",
  ]);
  const [shouldRedirect, setShouldRedirect] = useState(true);
  const historyPush = useHistoryPush();

  useEffect(() => {
    (async () => {
      if (signOut === "true") clearSession();

      if (isNewUser) {
        historyPush(ROUTES.ACCOUNT_SET_UP);
        setShouldRedirect(false);
        return;
      }
      if (redirectUrl && !isNewUser && signOut !== "true") {
        window.location.href = sessionApi.go(decodeURIComponent(redirectUrl));
      } else setShouldRedirect(false);
    })();
  }, []);

  return shouldRedirect;
};

const MyAccount: React.FC = () => {
  const { firestore } = useFirebase();
  const { user } = useUser();
  const [profile, isLoadingProfile] = useDocumentData(
    doc(firestore, `profiles/${user.uid}`)
  );

  return (
    <>
      {isLoadingProfile ? (
        <AppLoading />
      ) : (
        <MyAccountWithData profile={profile} />
      )}
    </>
  );
};

const MyAccountWithData: React.FC<{ profile: DocumentData }> = ({
  profile,
}) => {
  const shouldRedirect = useAuthRedirect(!profile);

  const formsUI = (
    <SectionWrapper>
      <Div maxw={540} mcontainer>
        <ContactInfo profile={profile} />
      </Div>
      <Divider mt={20} mb={20} forMobile />
      <PasswordForm mcontainer />
    </SectionWrapper>
  );

  return (
    <>
      {!shouldRedirect && (
        <AppLayout>
          <Navigation withBackButton={true} />
          <AppContainer>
            <H2 alignCenter mt={50} mcontainer>
              My Account
            </H2>
            {formsUI}
            {illustrationUI}
          </AppContainer>
          <Footer background={colors.white} />
        </AppLayout>
      )}
    </>
  );
};

export default MyAccount;
