var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from "styled-components";
var LoaderInlineWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n"])));
var Bounce = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 12px;\n  height: 12px;\n  margin: 0 5px 0 5px;\n  background: ", ";\n  @-webkit-keyframes sk-bouncedelay {\n    0%,\n    80%,\n    100% {\n      -webkit-transform: scale(0);\n    }\n    40% {\n      -webkit-transform: scale(1);\n    }\n  }\n  @keyframes sk-bouncedelay {\n    0%,\n    80%,\n    100% {\n      -webkit-transform: scale(0);\n      transform: scale(0);\n    }\n    40% {\n      -webkit-transform: scale(1);\n      transform: scale(1);\n    }\n  }\n  border-radius: 100%;\n  display: inline-block;\n  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;\n  animation: sk-bouncedelay 1.4s infinite ease-in-out both;\n"], ["\n  width: 12px;\n  height: 12px;\n  margin: 0 5px 0 5px;\n  background: ", ";\n  @-webkit-keyframes sk-bouncedelay {\n    0%,\n    80%,\n    100% {\n      -webkit-transform: scale(0);\n    }\n    40% {\n      -webkit-transform: scale(1);\n    }\n  }\n  @keyframes sk-bouncedelay {\n    0%,\n    80%,\n    100% {\n      -webkit-transform: scale(0);\n      transform: scale(0);\n    }\n    40% {\n      -webkit-transform: scale(1);\n      transform: scale(1);\n    }\n  }\n  border-radius: 100%;\n  display: inline-block;\n  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;\n  animation: sk-bouncedelay 1.4s infinite ease-in-out both;\n"])), function (props) { return props.color; });
var Bounce1 = styled(Bounce)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  -webkit-animation-delay: -0.32s;\n  animation-delay: -0.32s;\n"], ["\n  -webkit-animation-delay: -0.32s;\n  animation-delay: -0.32s;\n"])));
var Bounce2 = styled(Bounce)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  -webkit-animation-delay: -0.16s;\n  animation-delay: -0.16s;\n"], ["\n  -webkit-animation-delay: -0.16s;\n  animation-delay: -0.16s;\n"])));
var SpinnerContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 70px;\n  text-align: center;\n"], ["\n  width: 70px;\n  text-align: center;\n"])));
export var LoaderInline = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "#CCCCCC" : _b;
    return (_jsx(LoaderInlineWrapper, { children: _jsxs(SpinnerContainer, { children: [_jsx(Bounce1, { color: color }, void 0), _jsx(Bounce2, { color: color }, void 0), _jsx(Bounce, { color: color }, void 0)] }, void 0) }, void 0));
};
var LoadingBackdrop = styled("div")(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  width: 100vw;\n  height: 100vh;\n  z-index: 1000;\n  background: #dcdee0;\n  opacity: 0.2;\n"], ["\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  width: 100vw;\n  height: 100vh;\n  z-index: 1000;\n  background: #dcdee0;\n  opacity: 0.2;\n"])));
var LoaderInlineContainer = styled("div")(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  z-index: 1000;\n  width: 100vw;\n  height: 100vh;\n  margin-top: 115px;\n  pointer-events: none;\n  div {\n    pointer-events: auto;\n  }\n  pointer-events: none;\n"], ["\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  z-index: 1000;\n  width: 100vw;\n  height: 100vh;\n  margin-top: 115px;\n  pointer-events: none;\n  div {\n    pointer-events: auto;\n  }\n  pointer-events: none;\n"])));
var LoaderWrapper = styled("div")(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 300px;\n  margin-top: 100px;\n"], ["\n  width: 300px;\n  margin-top: 100px;\n"])));
var PRelative = styled("div")(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var AppLoading = function () { return (_jsxs(_Fragment, { children: [_jsx(LoadingBackdrop, {}, void 0), _jsx(LoaderInlineContainer, { children: _jsx(LoaderWrapper, { children: _jsx(PRelative, { children: _jsx(LoaderInline, {}, void 0) }, void 0) }, void 0) }, void 0)] }, void 0)); };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
