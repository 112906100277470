import React, { useState } from "react";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";

import { Button } from "@musicaudienceexchange/melodies/Button";
import {
  ErrorMessageWarningContainer,
  Form,
  FormikSumbitButton,
  FormikTextInput,
  yupEmail,
  yupRequired,
} from "@musicaudienceexchange/melodies/forms";
import { Link as LinkBase } from "@musicaudienceexchange/melodies/Link";
import { SvgKey } from "@musicaudienceexchange/melodies/Svgs/Key";
import { SvgMail } from "@musicaudienceexchange/melodies/Svgs/Mail";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { useAppContext } from "AppContextProvider";
import { useHistoryPush } from "components/common";

import styled, { css } from "styled-components";
import { FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE } from ".";
import { Layout, SectionHeader } from "./common";
import { useFirebase } from "providers/FirebaseProvider";

const Link = styled(LinkBase)(
  () => css`
    font-size: 12px;
  `
);

const fields = [
  {
    name: "email",
    label: "Email",
    validation: yupEmail,
    initialValue: "",
  },
  {
    name: "password",
    label: "Password",
    validation: yupRequired,
    initialValue: "",
  },
];

const Margin = styled(Div)`
  width: 10%;
`;

const emailLogin = async (email: string, password: string) => {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password);
};

const LoginWithPassword: React.FC = () => {
  const { setAppIsLoading } = useAppContext();
  const [isInvalidCredential, setIsInvalidCredential] = useState(false);
  const historyPush = useHistoryPush();

  const onSubmitForm = async (values: { email: string; password: string }) => {
    const { email, password } = values;
    try {
      setAppIsLoading(true);
      await emailLogin(email, password);
    } catch (err) {
      setIsInvalidCredential(true);
    }
    setAppIsLoading(false);
  };

  const fieldsUI = (
    <>
      <FormikTextInput
        mt={28}
        label="Email address"
        leftIcon={<SvgMail />}
        name="email"
        placeholder="john@email.com"
      />
      <FormikTextInput
        mt={35}
        label="Password"
        leftIcon={<SvgKey />}
        type="password"
        name="password"
        placeholder="Password…"
      />
    </>
  );

  const formUI = (
    <Form fields={fields} onSubmit={onSubmitForm}>
      {isInvalidCredential && (
        <ErrorMessageWarningContainer mt={30}>
          You entered an incorrect email, password, or both.
        </ErrorMessageWarningContainer>
      )}
      {fieldsUI}
      <Div justifyEnd>
        <Link
          mt={17}
          onClick={() => {
            historyPush(FORGOT_PASSWORD_ROUTE);
          }}
        >
          Forgot Password?
        </Link>
      </Div>
      <Div spaceBetween mt={30} mb={10}>
        <Button variant="secondary" onClick={() => historyPush(LOGIN_ROUTE)}>
          Back
        </Button>
        <Margin />
        <FormikSumbitButton>Login</FormikSumbitButton>
      </Div>
    </Form>
  );

  return (
    <Layout>
      <SectionHeader
        title="Log In"
        description="Please login with your email and password"
      />
      {formUI}
      <Div mt={15} />
    </Layout>
  );
};

export default LoginWithPassword;
