import React, { useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import AccountSetup from "./AccountSetUp";
import { AuthRedirect } from "./common";
import MyAccount from "./MyAccount";
import SessionRoutes, { SESSION_VIEW_PATH } from "./SessionRoutes";

import { useUser } from "auth";

export const ROUTES = {
  MY_ACCOUNT: "/my-account",
  ACCOUNT_SET_UP: "/account-setup",
};
export const DEFAULT_ROUTE = ROUTES.MY_ACCOUNT;

interface ProtectedRouteProps {
  path: string;
  children?: React.ReactNode | React.ReactNode[];
  exact: boolean;
  withLoginRedirect?: boolean;
}

const ProtectedRouteBase: React.FC<ProtectedRouteProps> = ({
  path,
  children,
  exact = false,
  withLoginRedirect = true,
}) => {
  const { isLoggedIn, redirectToLogin } = useUser();
  if (withLoginRedirect && !isLoggedIn) redirectToLogin();

  return (
    <>
      {isLoggedIn ? (
        <Route exact={exact} path={path}>
          {children}
        </Route>
      ) : (
        <AuthRedirect to={SESSION_VIEW_PATH} />
      )}
    </>
  );
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => (
  <ProtectedRouteBase {...props} withLoginRedirect={false} />
);

const Routes: React.FC = () => {
  const { isLoggedIn } = useUser();
  return (
    <>
      <RouterScrollToTop />
      <Switch>
        <Route path={SESSION_VIEW_PATH}>
          {isLoggedIn && <AuthRedirect to={DEFAULT_ROUTE} />}
          <SessionRoutes />
        </Route>
        <ProtectedRoute exact path={DEFAULT_ROUTE}>
          <MyAccount />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.ACCOUNT_SET_UP}>
          <AccountSetup />
        </ProtectedRoute>
        <Route
          component={() => (
            <AuthRedirect to={isLoggedIn ? DEFAULT_ROUTE : SESSION_VIEW_PATH} />
          )}
        />
      </Switch>
    </>
  );
};

const ScrollToTop = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
};
const RouterScrollToTop = withRouter(ScrollToTop);

export default Routes;
