import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { updatePassword } from "firebase/auth";

import { Button } from "@musicaudienceexchange/melodies/Button";
import {
  Form,
  FormikSumbitButton,
  FormikTextInput,
  yupPassword,
  yupPasswordConfirmation,
} from "@musicaudienceexchange/melodies/forms";
import {
  Modal as ModalBase,
  ModalWrapper,
} from "@musicaudienceexchange/melodies/Modal";
import { SvgKey } from "@musicaudienceexchange/melodies/Svgs/Key";
import { Body1, Caption } from "@musicaudienceexchange/melodies/Text";
import { addToast } from "@musicaudienceexchange/melodies/Toast";
import { Div, DivThemeCSSProps } from "@musicaudienceexchange/melodies/utils";
import { useAppContext } from "AppContextProvider";
import { useAuthQueryParams } from "components/common";

import { Section } from "..";
import { ROUTES } from "../..";
import { RenewCredentialsForm } from "./RenewCredentialsForm";

import { useUser } from "auth";

const Modal = styled(ModalBase)`
  ${ModalWrapper} {
    ${(p) => p.theme.mediaQueries.desktop} {
      width: 500px;
    }
  }
`;

const fields = [
  {
    label: "Password",
    name: "password",
    validation: yupPassword,
    initialValue: "",
  },
  {
    label: "Confirm Password",
    name: "confirmPassword",
    validation: yupPasswordConfirmation,
    initialValue: "",
  },
];

export const PasswordForm: React.FC<DivThemeCSSProps> = ({ ...props }) => {
  const { focusPassword } = useAuthQueryParams(["focusPassword"]);
  const [isEditing, setIsEditing] = useState<boolean>(!!focusPassword);

  const modalUI = (
    <>{isEditing && <NewPasswordForm setIsEditing={setIsEditing} />}</>
  );

  return (
    <Div {...props}>
      {modalUI}
      <Section>
        <Div w={200}>
          <Button variant="secondary" onClick={() => setIsEditing(true)}>
            Update Password
          </Button>
        </Div>
      </Section>
    </Div>
  );
};

const NewPasswordForm: React.FC<{
  setIsEditing: (isEditing: boolean) => unknown;
}> = ({ setIsEditing }) => {
  const { setAppIsLoading } = useAppContext();
  const { focusPassword } = useAuthQueryParams(["focusPassword"]);
  const [shouldRenewCredentials, setShouldRenewCredentials] = useState(false);
  const history = useHistory();
  const { user } = useUser();

  useEffect(() => {
    return () => {
      history.push(ROUTES.MY_ACCOUNT);
    };
  }, []);

  const onSubmitForm = async (values) => {
    const { password } = values;
    try {
      setAppIsLoading(true);
      await updatePassword(user, password);
      addToast("Password updated.");
      onClose();
    } catch (err) {
      setShouldRenewCredentials(true);
    }
    setAppIsLoading(false);
  };

  const onClose = () => setIsEditing(false);

  useEffect(() => {
    if (focusPassword) {
      const selector = `[name="password"] `;
      const inputElement = document.querySelector(selector) as any;
      if (inputElement) inputElement?.focus();
    }
  }, []);

  return (
    <Modal header="Update Password" isOpen={true} onClose={onClose}>
      {shouldRenewCredentials ? (
        <RenewCredentialsForm
          setShouldRenewCredentials={setShouldRenewCredentials}
        />
      ) : (
        <UpdatePasswordForm {...{ onSubmitForm, onClose }} />
      )}
    </Modal>
  );
};

const UpdatePasswordForm = ({ onSubmitForm, onClose }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.getElementsByTagName("input")[0].focus();
    }, 10);
  }, []);

  const fieldsUI = (
    <Div mt={15}>
      <Div ref={inputRef} tabIndex={0}>
        <FormikTextInput
          label="New Password"
          leftIcon={<SvgKey />}
          type="password"
          name="password"
          placeholder="Choose a new password..."
        />
      </Div>
      <Caption mt={8}>Password must contain at least six characters</Caption>
      <Div mt={30}>
        <FormikTextInput
          label="Confirm Password"
          leftIcon={<SvgKey />}
          type="password"
          name="confirmPassword"
          placeholder="Passwords must match..."
        />
      </Div>
    </Div>
  );

  return (
    <Form fields={fields} onSubmit={onSubmitForm}>
      <Body1 mt={25} mb={30}>
        {/*Epicurus in ea commodi consequatur? quis autem quidam e nostris.*/}
      </Body1>
      {fieldsUI}
      <Div justifyEnd mt={38}>
        <Button
          w={90}
          mr={10}
          variant="tertiary"
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
        <FormikSumbitButton w={80} ml={10}>
          Save
        </FormikSumbitButton>
      </Div>
    </Form>
  );
};
