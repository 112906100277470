import { Label as LabelBase } from "@musicaudienceexchange/melodies/Text";
import { colors } from "@musicaudienceexchange/melodies/Theme/colors";
import { Div, ThemeCSSProps } from "@musicaudienceexchange/melodies/utils";
import { Footer } from "components/common/Footer";
import { AppContainer, AppLayout } from "components/common/layout";
import { useQueryParams } from "hooks";
import React from "react";
import styled, { css } from "styled-components";
import { Navigation } from "../common/Navigation";
import bgDesktop from "./assets/bg-desktop.svg";
import bgNextSong from "./assets/bg-nextsong.svg";
import nextSongLogo from "./assets/logo-nextsong.svg";
import setliveLogo from "./assets/Logo.svg";

const Container = styled(Div)`
  width: 100%;
  max-width: 500px;
  border: 1px solid ${(p) => p.theme.colors.black40};
  border-radius: 12px;
  padding: 40px 50px;
  z-index: 10;
  ${(props) => props.theme.mediaQueries.desktop} {
    background-color: white;
  }
  ${(props) => props.theme.mediaQueries.mobile} {
    margin-top: 10px;
    border: none;
    ${(p) => p.theme.css.mcontainer};
  }
`;

const Label = styled(LabelBase)(
  ({ theme: { colors, css: c } }) => css`
    color: ${colors.black40};
  `
);

const Title = styled(Div)`
  font-weight: 500;
  font-size: 20px;
`;

const Description = styled(Div)`
  font-size: 12px;
  line-height: 18px;
  color: ${(p) => p.theme.colors.black60};
`;

export const SectionHeader: React.FC<{
  title: string | React.ReactNode;
  description: string | React.ReactNode;
}> = ({ title, description }) => {
  return (
    <Div>
      <Title>{title}</Title>
      <Description lite mt={12}>
        {description}
      </Description>
    </Div>
  );
};

type FieldProps = ThemeCSSProps & {
  label: string | React.ReactNode;
  value: string | React.ReactNode;
};

export const Field: React.FC<FieldProps> = ({ label, value, ...props }) => {
  return (
    <Div {...props}>
      <Label>{label}</Label>
      <Div mt={5} fs={17} c={colors.black80}>
        {value}
      </Div>
    </Div>
  );
};

type BackgroundGraphicsProps = {
  isNextSong: boolean;
};
const BackgroundGraphics = styled(Div)<BackgroundGraphicsProps>(
  ({ theme: { colors, css: c }, isNextSong }) => css`
    position: absolute;
    height: 1200px;
    width: 100%;
    bottom: 0;
    overflow: hidden;
    background-position: 30% 0%;
    pointer-events: none;
    background-size: cover;
    z-index: -1;
    background-image: url(${isNextSong ? bgNextSong : bgDesktop});
    opacity: 0.6;
    @media all and (min-width: 545px) {
      background-image: url(${isNextSong ? bgNextSong : bgDesktop});
    }
  `
);

const LayoutContainer = styled(Div)`
  width: 100%;
  ${(p) => p.theme.css.centered};
  ${(props) => props.theme.mediaQueries.mobile} {
    padding: 0 27px;
  }
`;

const Header = styled(Div)`
  font-size: 38px;
  font-weight: 600;
  line-height: 54px;
  color: black;
  margin-bottom: 27px;
  margin-top: 56px;
  ${(props) => props.theme.mediaQueries.mobile} {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 0px;
  }
`;

const appDescriptions = {
  artists: "Artist Portal",
  nextsong: "Next Song",
  myshoutouts: "Shoutout Management",
  mymeet: "Meet & Greet Management",
  meet: "Meet & Greet Purchasing",
  video: "Meet & Greet Video",
};

export const Layout: React.FC = ({ children }) => {
  const { redirect } = useQueryParams(["redirect"]);

  // maps redirect subdomain to app description
  const appDescription =
    appDescriptions[
      Object.keys(appDescriptions).find((k) => redirect?.includes(`${k}.`))
    ];

  const isNextSong = redirect?.includes("nextsong.live");
  const headerUI = (
    <LayoutContainer w100 maxw={500} dflex>
      <Div w100 alignCenter>
        <Div mt={30} ml={0} w={105}>
          <img src={isNextSong ? nextSongLogo : setliveLogo} />
        </Div>
        <Header ml={18} maxw={405} w100>
          {appDescription}
        </Header>
      </Div>
    </LayoutContainer>
  );

  return (
    <>
      <AppLayout>
        <Navigation />
        <AppContainer>
          <Div w100 centered flexColumn>
            {appDescription ? headerUI : <Div mb={65} />}
            <Container>{children}</Container>
          </Div>
        </AppContainer>
        <Div mt={60} pRelative>
          <BackgroundGraphics isNextSong={isNextSong} />
          <Footer background={colors.white} />
        </Div>
      </AppLayout>
    </>
  );
};
