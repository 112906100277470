import React, { useState } from "react";
import styled, { css } from "styled-components";
import type { DocumentData } from "firebase/firestore";

import { Body1 as Body1Base, H3 } from "@musicaudienceexchange/melodies/Text";
import { colors } from "@musicaudienceexchange/melodies/Theme/colors";
import {
  Div,
  propsToThemeCSS,
  ThemeCSSProps,
} from "@musicaudienceexchange/melodies/utils";
import { Divider } from "components/common";

import { Section } from "../index";
import { ContactInfoForm } from "./ContactInfoForm";
import { useUser } from "auth";

const Body1 = styled(Body1Base)(
  ({ theme: { colors, css: c } }) => css`
    color: ${colors.black60};
  `
);

const Avatar = styled.img<ThemeCSSProps>`
  ${propsToThemeCSS};
  border-radius: 100%;
  height: 40px;
  width: 40px;
`;

const EditButton = styled(Div)`
  color: ${(p) => p.theme.colors.navy};
  margin-left: 5px;
  font-size: 15px;
  margin-top: 2px;
  ${(p) => {
    const c = p.theme.css;
    return [c.clickable];
  }};
`;

export const ContactInfo: React.FC<{ profile: DocumentData }> = ({
  profile,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useUser();

  const headerUI = (
    <Div dflex alignCenter>
      <H3>Contact Information</H3>
      {!isEditing && (
        <EditButton onClick={() => setIsEditing(true)}>(edit)</EditButton>
      )}
    </Div>
  );

  const contactSummaryUI = (
    <Div fs={15}>
      <Div semiBold c={colors.black80} alignCenter>
        {user.photoURL && <Avatar src={user.photoURL} mr={14} />}
        <Div> {user.displayName}</Div>
      </Div>
      <Body1 mt={14}>{user.email}</Body1>
    </Div>
  );

  return (
    <Section>
      {headerUI}
      <Div mt={18}>
        {isEditing ? (
          <ContactInfoForm profile={profile} setIsEditing={setIsEditing} />
        ) : (
          contactSummaryUI
        )}
        <Divider mt={40} forDesktop />
      </Div>
    </Section>
  );
};
