import { SvgUpload } from "@musicaudienceexchange/melodies/Svgs/Upload";
import { colors } from "@musicaudienceexchange/melodies/Theme/colors";
import { Div } from "@musicaudienceexchange/melodies/utils";
import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { happyCloudSvg, sadCloudSvg } from "./svgs";

const PlaceholderText = styled(Div)`
  width: 288px;
  ${(props) => props.theme.mediaQueries.mobile} {
    width: 80%;
  }
`;

const contentOptions = {
  inactive: {
    message: "Drag and drop your image",
    icon: (
      <Div c={colors.black20}>
        <SvgUpload width="50px" height="50px" />
      </Div>
    ),
  },
  active: {
    valid: {
      message: "Drop It!",
      icon: happyCloudSvg,
    },
    invalid: {
      message: "Are you sure this is an image?",
      icon: sadCloudSvg,
    },
  },
};

const DefaultWrapper = ({ children }) => {
  return <Div>{children}</Div>;
};

const ImageDropzone = ({
  onSelect,
  placeholderText = "",
  Wrapper = DefaultWrapper,
}) => {
  const dzProps = useDropzone({
    accept: "image/*",
    onDrop: ([file]) => {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => onSelect(reader.result);
      }
    },
  });
  const { getRootProps, getInputProps, isDragActive, isDragAccept } = dzProps;

  const content = !isDragActive
    ? contentOptions.inactive
    : contentOptions.active[isDragAccept ? "valid" : "invalid"];

  const contentUI = (
    <Div>
      <Div centered mt={-10}>
        {content.icon}
      </Div>
      <Div bold mt={10}>
        {content.message}
      </Div>
    </Div>
  );

  return (
    <Div c={colors.black80} clickable fs={17} textAlignCenter>
      <Wrapper {...getRootProps()}>
        <input {...getInputProps()} />
        <Div>
          {contentUI}
          {!isDragActive && (
            <Div dflex centered mt={8}>
              or
              <Div ml={5} underline bold c={colors.navy}>
                click to browse
              </Div>
            </Div>
          )}
          <Div centered mt={10}>
            <PlaceholderText lite fs={12} c={colors.black60} mt={5}>
              {placeholderText}
            </PlaceholderText>
          </Div>
        </Div>
      </Wrapper>
    </Div>
  );
};

export default ImageDropzone;
