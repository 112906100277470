import React, { useEffect } from "react";
import { useFormikContext } from "formik";

import { Button } from "@musicaudienceexchange/melodies/Button";
import {
  Form,
  FormikTextInput,
  yupEmail,
} from "@musicaudienceexchange/melodies/forms";
import { SvgMail } from "@musicaudienceexchange/melodies/Svgs/Mail";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { useHistoryPush } from "components/common";

import { LOGIN_WITH_PASSWORD_ROUTE } from ".";
import { useLogin } from "../common/useLogin";
import { Layout, SectionHeader } from "./common";

const emailField = {
  label: "Email",
  name: "email",
  validation: yupEmail,
  initialValue: "",
};

const ForgotPassword: React.FC = () => {
  const {
    isSubmitButtonDisabled,
    setIsSubmitButtonDisabled,
    onSubmitForm: onEmailLogin,
    emailSentUI,
  } = useLogin(true);

  return (
    <Layout>
      <SectionHeader
        title="Forgot Password"
        description="Please enter the email address attached to your SET account below, and you will receive a one-time login link."
      />
      <Form fields={[emailField]} onSubmit={onEmailLogin}>
        <FormikTextInput
          mt={30}
          label="Email address"
          withDebounce={false}
          leftIcon={<SvgMail />}
          name="email"
          placeholder="john@email.com"
        />
        {emailSentUI}
        <SubmitButton
          isButtonDisabled={isSubmitButtonDisabled}
          setIsButtonDisabled={setIsSubmitButtonDisabled}
        />
      </Form>
    </Layout>
  );
};

const SubmitButton = ({ isButtonDisabled, setIsButtonDisabled }) => {
  const { values, submitForm } = useFormikContext<any>();
  const { email } = values;
  const historyPush = useHistoryPush();

  const goToLogin = () => {
    historyPush(LOGIN_WITH_PASSWORD_ROUTE);
  };

  useEffect(() => {
    setIsButtonDisabled(false);
  }, [email]);

  return (
    <Div spaceBetween mt={30} mb={10}>
      <Button mr={8} variant="secondary" onClick={goToLogin}>
        Back
      </Button>
      <Button
        ml={8}
        isDisabled={isButtonDisabled}
        variant="primary"
        onClick={submitForm}
      >
        Locate Account
      </Button>
    </Div>
  );
};

export default ForgotPassword;
