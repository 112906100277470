import { Div } from "@musicaudienceexchange/melodies/utils";
import { useQueryParams } from "hooks";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { getUrl } from "utils";

// used to preserve redirect query param in url
export const useHistoryPush = () => {
  const location = useLocation();
  const history = useHistory();
  return (route) => history.push({ pathname: route, search: location.search });
};

const queryParams = ["redirect", "signOut", "focusPassword"] as const;
export type QueryParam = typeof queryParams[number];

export type AuthUrlParams = { [k in QueryParam]?: string };
export type GetAuthUrlParams = {
  domain?: string;
  path?: string;
  params?: AuthUrlParams;
};
export const getAuthUrl = (params: GetAuthUrlParams) => getUrl(params);

export const useAuthQueryParams = (queryParams: QueryParam[]): AuthUrlParams =>
  useQueryParams(queryParams as any);

export const Divider = styled(Div)`
  height: 1px;
  width: 100%;
  background: ${(p) => p.theme.colors.gray3};
`;
