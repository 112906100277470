import { Div } from "@musicaudienceexchange/melodies/utils";
import React from "react";
import styled from "styled-components";

export const AppLayout = styled(Div)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
`;

export const Container = styled(Div)`
  ${(p) => p.theme.css.appPaddingLeft};
  ${(p) => p.theme.css.appPaddingRight};
  width: 100%;
  max-width: 1224px;
`;

export const AppContainer = ({ children, className = "" }) => {
  return (
    <Div justifyCenter className={className}>
      <Container mcontainer>{children}</Container>
    </Div>
  );
};
