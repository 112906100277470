import { Caption as CaptionBase } from "@musicaudienceexchange/melodies/Text";
import { colors } from "@musicaudienceexchange/melodies/Theme/colors";
import { Div } from "@musicaudienceexchange/melodies/utils";
import React from "react";
import styled, { css } from "styled-components";
import { AppContainer } from "./layout";

const Caption = styled(CaptionBase)(
  ({ theme: { colors, css: c } }) => css`
    color: black;
  `
);

type ContainerProps = {
  background: string;
};
const Container = styled(Div)<ContainerProps>`
  color: ${(p: any) => p.color};
  background: ${(p: any) => p.background};
  padding-top: 30px;
  svg {
    path {
      fill: ${(p) => p.color};
    }
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    padding-bottom: 10px;
  }
`;

const FooterLink = styled(Div)`
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.color};
  :hover,
  :focus {
    color: ${(props) => props.color};
  }
  margin-right: 12px;
  margin-left: 12px;
  ${(props) => props.theme.mediaQueries.mobile} {
    margin-top: 20px;
  }
`;

type WrapperProps = {
  withLogo: boolean;
};
const Wrapper = styled(Div)<WrapperProps>`
  width: 100%;
  ${(p) =>
    p.withLogo
      ? css`
          display: flex;
          justify-content: space-between;
        `
      : css`
          display: grid;
          place-items: center;
        `}
  padding-bottom: 35px;
  ${(p) => p.theme.mediaQueries.mobile} {
    display: block;
    padding-bottom: 4%;
  }
`;

const LinksWrapper = styled(Div)`
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-top: 10px;
    ${(p) => p.theme.css.centered};
  }
`;

// used for centering footer with spaceBetween
const InvisibleDiv = styled(Div)`
  opacity: 0;
`;

const links = [
  {
    label: "About SET",
    url: "https://about.set.live",
  },
  {
    label: "Privacy Policy",
    path: "/privacy",
  },
  {
    label: "Terms of Use",
    path: "/terms",
  },
  {
    label: "Contact Us",
    path: "/contact",
  },
  {
    label: "FAQ",
    path: "/faq",
  },
];

type Props = {
  withLogo?: boolean;
  color?: string;
  background?: string;
};

export const Footer = ({
  withLogo = true,
  color = colors.black,
  background = colors.black40,
}: Props) => {
  const gotoPage = (path) => {
    window.open(`${process.env.REACT_APP_FIREBASE_SETLIVE_URL}${path}`);
  };

  const copyrightUI = <Caption>© 2022 Set.Live</Caption>;

  return (
    <Container color={color} background={"transparent"} mcontainer>
      <AppContainer>
        <Wrapper withLogo={withLogo}>
          <Div forDesktop>{copyrightUI}</Div>
          <LinksWrapper flexWrap>
            {links.map((link, index) => (
              <FooterLink
                color={color}
                clickable
                key={index}
                onClick={() => {
                  if (link.path) gotoPage(link.path);
                  if (link.url) window.open(link.url);
                }}
              >
                {link.label}
              </FooterLink>
            ))}
          </LinksWrapper>
          <Div forMobile mt={30} centered>
            {copyrightUI}
          </Div>
          <InvisibleDiv forDesktop>{copyrightUI}</InvisibleDiv>
        </Wrapper>
      </AppContainer>
    </Container>
  );
};
