import { Button } from "@musicaudienceexchange/melodies/Button";
import { Form, FormikTextInput } from "@musicaudienceexchange/melodies/forms";
import { Link as LinkBase } from "@musicaudienceexchange/melodies/Link";
import { SvgMail } from "@musicaudienceexchange/melodies/Svgs/Mail";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { useHistoryPush } from "components/common";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { LOGIN_WITH_PASSWORD_ROUTE } from ".";
import { emailField, useLogin } from "../common/useLogin";
import { Layout, SectionHeader } from "./common";

const Link = styled(LinkBase)(
  () => css`
    font-size: 12px;
  `
);

const Login: React.FC = () => {
  const historyPush = useHistoryPush();

  const {
    isSubmitButtonDisabled,
    setIsSubmitButtonDisabled,
    onSubmitForm,
    emailSentUI,
  } = useLogin(false);

  const formUI = (
    <Form fields={[emailField]} onSubmit={onSubmitForm}>
      <FormikTextInput
        mt={30}
        label="Email address"
        withDebounce={false}
        leftIcon={<SvgMail />}
        name="email"
        placeholder="john@email.com"
      />
      {emailSentUI}
      <SubmitButton
        isButtonDisabled={isSubmitButtonDisabled}
        setIsButtonDisabled={setIsSubmitButtonDisabled}
      />
    </Form>
  );

  return (
    <Layout>
      <SectionHeader
        title="Log In"
        description="Please enter the email address attached to your SET account below, and you will receive a one-time login link."
      />
      {formUI}
      <Div mb={20} mt={20} spaceBetween>
        <Div semiBold>
          <Link onClick={() => historyPush(LOGIN_WITH_PASSWORD_ROUTE)}>
            Login with password
          </Link>
        </Div>
      </Div>
    </Layout>
  );
};

const SubmitButton: React.FC<{
  isButtonDisabled: boolean;
  setIsButtonDisabled: (isButtonDisabled: boolean) => unknown;
}> = ({ isButtonDisabled, setIsButtonDisabled }) => {
  const { values: email, submitForm } = useFormikContext();

  useEffect(() => {
    setIsButtonDisabled(false);
  }, [email]);

  return (
    <Div spaceBetween mt={30} mb={10}>
      <Button
        isDisabled={isButtonDisabled}
        variant="primary"
        onClick={submitForm}
      >
        Log In
      </Button>
    </Div>
  );
};
export default Login;
