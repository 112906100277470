import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { Theme } from "@musicaudienceexchange/melodies/Theme";
import { Toast } from "@musicaudienceexchange/melodies/Toast";

import { AppContextProvider } from "AppContextProvider";
import { FirebaseProvider } from "providers/FirebaseProvider";
import { UserProvider } from "auth";
import Routes from "components/Routes";
import { LogrocketHelper } from "LogrocketHelper";

import "./App.css";

function App() {
  return (
    <FirebaseProvider>
      <Router>
        <Theme>
          <AppContextProvider>
            <UserProvider>
              <LogrocketHelper />
              <Toast />
              <Routes />
            </UserProvider>
          </AppContextProvider>
        </Theme>
      </Router>
    </FirebaseProvider>
  );
}

export default App;
