import React, { useEffect } from "react";
import { doc, DocumentData } from "firebase/firestore";

import {
  Form,
  FormikCheckboxInput,
  FormikNameInput,
  FormikSumbitButton,
  yupRequired,
} from "@musicaudienceexchange/melodies/forms";
import { useAppContext } from "AppContextProvider";
import { AppLoading } from "components/AppLoading";
import { useHistoryPush } from "components/common";
import { useDocumentData } from "react-firebase-hooks/firestore";
import * as yup from "yup";
import { DEFAULT_ROUTE } from ".";
import { Field, Layout, SectionHeader } from "./SessionRoutes/common";

import { useUser } from "auth";
import { useFirebase } from "providers/FirebaseProvider";

const AccountSetup: React.FC = () => {
  const { firestore } = useFirebase();
  const { user } = useUser();
  const [profile, isLoadingProfile] = useDocumentData(
    doc(firestore, `profiles/${user.uid}`)
  );

  return (
    <>
      {isLoadingProfile ? (
        <AppLoading />
      ) : (
        <AccountSetupWithContext profile={profile} />
      )}
    </>
  );
};

const fields = [
  {
    label: "First Name",
    name: "firstName",
    validation: yupRequired,
    initialValue: "",
  },
  {
    label: "Last Name",
    name: "lastName",
    validation: yupRequired,
    initialValue: "",
  },
  {
    label: "",
    name: "termsAgreement",
    validation: yup
      .boolean()
      .oneOf([true], "You must confirm that you are at least 13 years old."),
    initialValue: false,
  },
];

const AccountSetupWithContext: React.FC<{ profile: DocumentData }> = ({
  profile,
}) => {
  const { user, updateName } = useUser();
  const { setAppIsLoading } = useAppContext();
  const historyPush = useHistoryPush();

  useEffect(() => {
    if (profile) historyPush(DEFAULT_ROUTE);
  }, [user]);

  const onSubmitForm = async (values: {
    firstName: string;
    lastName: string;
  }) => {
    const { firstName, lastName } = values;
    setAppIsLoading(true);
    await updateName(firstName, lastName);
    setAppIsLoading(false);
    historyPush(DEFAULT_ROUTE);
  };

  const fieldsUI = (
    <>
      <Field mt={25} mb={25} label="Email address" value={user?.email} />
      <FormikNameInput mb={20} />
      <FormikCheckboxInput
        label="I am at least 13 years of age"
        name="termsAgreement"
        description=""
      />
    </>
  );

  return (
    <Layout>
      <SectionHeader
        title="Account Set-Up"
        description="Please enter your name below to register"
      />
      <Form fields={fields} onSubmit={onSubmitForm}>
        {fieldsUI}
        <FormikSumbitButton mt={35} mb={10}>
          Continue
        </FormikSumbitButton>
      </Form>
    </Layout>
  );
};

export default AccountSetup;
