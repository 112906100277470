import React, { useState } from "react";

import { sessionApi } from "@musicaudienceexchange/firebase-auth-ui";
import { yupEmail } from "@musicaudienceexchange/melodies/forms";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { useAppContext } from "AppContextProvider";
import {
  AuthUrlParams,
  getAuthUrl,
  useAuthQueryParams,
} from "components/common";
import styled from "styled-components";

const Message = styled(Div)`
  padding: 16px;
  font-size: 14px;
  font-weight: 300px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.black80};
  border: 1px solid ${(props) => props.theme.colors.yellow20};
  background-color: ${(props) => props.theme.colors.yellow10};
  margin-top: 26px;
  border-radius: 8px;
  span {
    font-weight: bold;
  }
`;

export const emailField = {
  label: "Email address",
  name: "email",
  initialValue: "",
  validation: yupEmail,
};

export const useLogin = (focusPasswordOnLogin = false) => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { setAppIsLoading } = useAppContext();
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const { redirect } = useAuthQueryParams(["redirect"]);
  const [email, setEmail] = useState(null);

  const onSubmitForm = async (values) => {
    const { email } = values;
    setAppIsLoading(true);
    setIsSubmitButtonDisabled(true);
    try {
      let params: AuthUrlParams = {};
      if (redirect) params = { redirect };
      if (focusPasswordOnLogin) params.focusPassword = "true";
      const res = await sessionApi.login(email, getAuthUrl({ params }));

      if (res) {
        setEmail(email);
        setIsEmailSent(true);
      }
    } catch (err) {
      console.log(err);
    }
    setTimeout(() => {
      // session.login should not be called more than once per 10 seconds
      setIsSubmitButtonDisabled(false);
    }, 10000);
    setAppIsLoading(false);
  };

  const emailSentUI = (
    <>
      {isEmailSent && (
        <Message>
          An email to access your account has been sent to <span>{email}</span>
        </Message>
      )}
    </>
  );

  return {
    isSubmitButtonDisabled,
    setIsSubmitButtonDisabled,
    onSubmitForm,
    emailSentUI,
  };
};
